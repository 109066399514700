using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using DriveScripts;
using Newtonsoft.Json;

namespace Scripts
{
    public static class Web
    {
        private static string FormatUrl(string url, RequestOptions options)
        {
            var u = new Url(url);

            if (options.PathParams != null)
            {
                foreach (string propertyName in GetOwnPropertyNames(options.PathParams))
                {
                    string pathParam = "{" + propertyName + "}";
                    object propertyValue = options.PathParams[propertyName];
                    u.Path = u.Path.Replace(pathParam, propertyValue != null ? Window.EncodeURIComponent(propertyValue.ToString()) : "");
                }
            }

            if (options.QueryParams != null)
            {
                foreach (string propertyName in GetOwnPropertyNames(options.QueryParams))
                {
                    object propertyValue = options.QueryParams[propertyName];
Array valueAsArray;                    if ((valueAsArray = propertyValue as Array) != null)
                    {
                        foreach (object singleValue in valueAsArray)
                        {
                            if (singleValue == null)
                                continue;

                            u.AppendParameter(propertyName, singleValue.ToString());
                        }
                    }
                    else if (propertyValue != null)
                    {
                        u.SetParameter(propertyName, propertyValue.ToString());
                    }
                }
            }

            return u.ToString();
        }

        private static async Task<T> PerformRequestAsync<T>(string url, string method, RequestOptions options) where T : class
        {
            options = options ?? new RequestOptions();

            url = FormatUrl(url, options);

            var tcs = new TaskCompletionSource<XMLHttpRequest>();

            var xmlHttp = new XMLHttpRequest();
            xmlHttp.Open(method, url, true);
            xmlHttp.WithCredentials = options.WithCredentials;
            xmlHttp.OnReadyStateChange = () =>
            {
                if (xmlHttp.ReadyState != AjaxReadyState.Done)
                {
                    return;
                }

                if (xmlHttp.Status >= 200 && xmlHttp.Status <= 299 || xmlHttp.Status == 304)
                {
                    tcs.SetResult(xmlHttp);
                }
                else
                {
                    tcs.SetException(new WebException(xmlHttp.Status, xmlHttp.StatusText, xmlHttp.ResponseText));
                }
            };

            xmlHttp.SetRequestHeader("Accept", "application/json;charset=UTF-8");
            if (options.Body is FormData)
            {
                xmlHttp.Send(options.Body as FormData);
            }
            else if (options.Body != null)
            {
                if (options.BodyEncoding == RequestType.Json)
                {
                    xmlHttp.SetRequestHeader("Content-Type", "application/json;charset=UTF-8");
                    xmlHttp.Send(JsonConvert.SerializeObject(options.Body));
                }
                else if (options.BodyEncoding == RequestType.UrlEncoded)
                {
System.Func<string, string> encode = null;
                    string s = null;
                    
encode = (ss) =>
{
    return Window.EncodeURIComponent(ss).Replace("%20", "+");
}

;
                    foreach (string propertyName in GetOwnPropertyNames(options.Body))
                    {
                        object value = options.Body[propertyName];
                        s += (s != null ? "&" : "") + encode(propertyName) + '=' + encode(value.ToString());
                    }
                    xmlHttp.SetRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
                    xmlHttp.Send(s);
                }
                else if (options.BodyEncoding == RequestType.FormData)
                {
                    var data = new FormData();
                    foreach (string propertyName in GetOwnPropertyNames(options.Body))
                    {
                        object value = options.Body[propertyName];
                        data.Append(propertyName, value!=null?value.ToString():(string)null);
                    }
                    xmlHttp.Send(data);
                }
            }
            else
            {
                xmlHttp.Send();
            }

            await tcs.Task;

            if (String.IsNullOrWhiteSpace(xmlHttp.ResponseText))
            {
                return null;
            }

            string responseContentType = xmlHttp.GetResponseHeader("Content-Type");
            if (responseContentType.Contains("application/json"))
            {
                return JsonConvert.DeserializeObject<T>(xmlHttp.ResponseText);
            }
            else if (typeof(T) == typeof(string))
            {
                return (T)(object)xmlHttp.ResponseText;
            }

            throw new InvalidOperationException("Did not receive json");
        }

        public static async Task GetAsync(string url, RequestOptions options = null)
        {
            await PerformRequestAsync<object>(url, "GET", options);
        }

        public static async Task<T> GetAsync<T>(string url, RequestOptions options = null) where T : class
        {
            return await PerformRequestAsync<T>(url, "GET", options);
        }

        public static async Task PostAsync(string url, RequestOptions options = null)
        {
            await PerformRequestAsync<object>(url, "POST", options);
        }

        public static async Task<T> PostAsync<T>(string url, RequestOptions options = null) where T : class
        {
            return await PerformRequestAsync<T>(url, "POST", options);
        }

        public static async Task PutAsync(string url, RequestOptions options = null)
        {
            await PerformRequestAsync<object>(url, "PUT", options);
        }

        public static async Task<T> PutAsync<T>(string url, RequestOptions options = null) where T : class
        {
            return await PerformRequestAsync<T>(url, "PUT", options);
        }

        public static async Task DeleteAsync(string url, RequestOptions options = null)
        {
            await PerformRequestAsync<object>(url, "DELETE", options);
        }

        public static async Task<T> DeleteAsync<T>(string url, RequestOptions options = null) where T : class
        {
            return await PerformRequestAsync<T>(url, "DELETE", options);
        }

        public static async Task<T> PostAsync<T>(string url, object data) where T : class
        {
            return await PerformRequestAsync<T>(url, "POST", new RequestOptions { Body = data, BodyEncoding = RequestType.UrlEncoded });
        }
    }

    public sealed class RequestOptions
    {
        /// <summary>
        /// Sendet Cookies in CORS requests mit
        /// </summary>
        public bool WithCredentials { get; set; }
        public object PathParams { get; set; }
        public object QueryParams { get; set; }
        public object Body { get; set; }
        public RequestType BodyEncoding { get; set; }

    
private RequestType __Property__Initializer__BodyEncoding=RequestType.Json;}

    public sealed class WebException : Exception
    {
        public string ResponseText { get; set; }
        public int StatusCode { get; set; }
        public string StatusText { get; set; }

        public WebException(int statusCode, string statusText, string responseText) : base(GetMessage(statusCode, statusText))
        {
            StatusCode = statusCode;
            StatusText = statusText;
            ResponseText = responseText;
        }

        private static string GetMessage(int statusCode, string statusText)
        {
            return "Response status code does not indicate success: " + statusCode + " (" + statusText + ")";
        }
    }

    public enum RequestType
    {
        Json,
        UrlEncoded,
        FormData
    }

    public static class Extensions
    {
        public static T SetText<T>(this T source, string value) where T : Element
        {
            source.InnerHTML = "";
            source.AppendChild(new Text(value));
            return source;
        }

        public static T AppendText<T>(this T source, string value) where T : Node
        {
            source.AppendChild(new Text(value));
            return source;
        }

        public static void AppendAllChildren(this Node source, IEnumerable<Node> nodes)
        {
            foreach (var node in nodes)
            {
                source.AppendChild(node);
            }
        }

        public static string NullWhenWhitespace(this string source)
        {
            return String.IsNullOrWhiteSpace(source) ? null : source;
        }

        public static void SetChecked(this HTMLInputElement checkbox, bool? value, bool allowNull)
        {
            checkbox.OnClick = allowNull ? ThreeStateClick : (Action<MouseEvent<HTMLInputElement>>)null;
            if (value.HasValue)
            {
                checkbox.Indeterminate = false;
                checkbox.Checked = value.Value;
                checkbox.Dataset["checked"] = value.Value ? "1" : "0";
            }
            else
            {
                checkbox.Indeterminate = true;
                checkbox.Dataset["checked"] = "2";
            }
        }

        private static void ThreeStateClick(MouseEvent<HTMLInputElement> ev)
        {
            var el = ev.CurrentTarget;
int state;
            Int32.TryParse(el.Dataset["checked"], out state);

            switch (state)
            {
                // unchecked, going checked
                case 0:
                    el.Checked = true;
                    el.Indeterminate = false;
                    el.Dataset["checked"] = "1";
                    break;

                // checked, going indeterminate
                case 1:
                    el.Indeterminate = true;
                    el.Dataset["checked"] = "2";
                    break;

                // indeterminate, going unchecked
                case 2:
                    el.Checked = false;
                    el.Indeterminate = false;
                    el.Dataset["checked"] = "0";
                    break;
            }
        }

        public static bool? GetChecked(this HTMLInputElement checkbox)
        {
            if (checkbox.Indeterminate)
                return null;

            return checkbox.Checked;
        }
    }
}
