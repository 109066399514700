using System.Threading.Tasks;
using Assfinet.Account.Common.Models;
using Bridge.Html5;
using Newtonsoft.Json;
using Scripts;

namespace AccountScripts
{
    public static class AccountApi
    {
        public static Task UploadAvatar(File avatar)
        {
            var data = new FormData();
            data.Append("file", avatar);

            return Web.PostAsync("/api/v1/Account/Avatar", new RequestOptions { WithCredentials = true, Body = data });
        }

        public static Task<CustomizationConfiguration> GetCustomization()
        {
            return Web.GetAsync<CustomizationConfiguration>("/api/v1/Customization/Configuration", new RequestOptions { WithCredentials = true });
        }

        public static Task SetCustomization(File logo, File logoApp, File style, File styleApp, File favicon, File faviconApp,
            File mobileIcon, File accountCertificate, File appCertificate, CustomizationConfiguration customization, File welcomeImage, File agbFile)
        {
            var data = new FormData();

            if (logo != null)
            {
                data.Append("logo", logo);
            }

            if (style != null)
            {
                data.Append("style", style);
            }

            if (styleApp != null)
            {
                data.Append("styleApp", styleApp);
            }

            if (logoApp != null)
            {
                data.Append("logoApp", logoApp);
            }

            if (favicon != null)
            {
                data.Append("favicon", favicon);
            }

            if (faviconApp != null)
            {
                data.Append("faviconApp", faviconApp);
            }

            if (mobileIcon != null)
            {
                data.Append("mobileIcon", mobileIcon);
            }

            if (accountCertificate != null)
            {
                data.Append("accountCertificate", accountCertificate);
            }

            if (appCertificate != null)
            {
                data.Append("appCertificate", appCertificate);
            }

            if (welcomeImage != null)
            {
                data.Append("welcomeImage", welcomeImage);
            }

            if (agbFile != null)
            {
                data.Append("agbFile", agbFile);
            }

            data.Append("model", JsonConvert.SerializeObject(customization));

            return Web.PostAsync("/api/v1/Customization/Configuration", new RequestOptions { WithCredentials = true, Body = data });
        }

        public static Task ResetCustomization()
        {
            return Web.DeleteAsync("/api/v1/Customization/Configuration", new RequestOptions { WithCredentials = true });
        }

        public static Task RemoveCss()
        {
            return Web.DeleteAsync("/api/v1/Customization/CSS", new RequestOptions { WithCredentials = true });
        }

        public static Task RemoveAppCss()
        {
            return Web.DeleteAsync("/api/v1/Customization/AppCSS", new RequestOptions { WithCredentials = true });
        }

        public static Task EmailTemplatesJsonImport(File json)
        {
            var data = new FormData();
            data.Append("file", json);

            return Web.PostAsync("api/v1/EmailSettings/ImportEmailTemplates", new RequestOptions { WithCredentials = true, Body = data });
        }

        public static Task<UserProfileModel> GetUserProfile()
        {
            return Web.GetAsync<UserProfileModel>("api/v1/Account/Profile");
        }

        public static Task SaveUserProfile(UserProfileModel profile)
        {
            return Web.PostAsync("api/v1/Account/Profile", new RequestOptions { Body = profile });
        }
    }
}
