using System;
using System.Linq;
using System.Threading.Tasks;
using AccountScripts.Libraries;
using Assfinet.Account.Common.Models;
using Assfinet.Drive.Api.Models;
using Bridge.Html5;
using Bridge.jQuery2;
using DriveScripts;
using DriveScripts.Libraries;
using Newtonsoft.Json;
using Scripts;

namespace AccountScripts
{
    public static class Manage
    {
        public static async Task UploadAvatar(HTMLButtonElement sender)
        {
            using (new DisabledButton(sender, "Wird hochgeladen..."))
            {
                HTMLInputElement urlBox = Document.GetElementById<HTMLInputElement>("Manage_AvatarFileInput");
                try
                {
                    await AccountApi.UploadAvatar(System.Linq.Enumerable.First<File>(urlBox.Files));
                    jQuery.Select(sender).Notify("Bild erfolgreich hochgeladen!", NotifyStyle.Success);
                }
                catch
                {
                    jQuery.Select(sender).Notify("Hochladen fehlgeschlagen.", NotifyStyle.Error);
                }

                var img = Document.GetElementById<HTMLImageElement>("Manage_AvatarImage");
                var url = new Url(img.Src);
                url.SetParameter("v", DateTime.UtcNow.Ticks.ToString());
                img.Src = url.ToString();
            }
        }

        public static async Task LoadCustomizations(HTMLButtonElement sender)
        {
            var custSave = Document.GetElementById<HTMLButtonElement>("constAuthEndpoint_Save");
            var custDiv = Document.GetElementById<HTMLDivElement>("CustView_Aussehen");

            using (new Disabled(custDiv))
            using (new DisabledButton(custSave, "Wird geladen..."))
            {
                var model = await AccountApi.GetCustomization();

                Document.GetElementById<HTMLInputElement>("titelInput").Value = (model!=null?model.Titel:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("accountServerUrlInput").Value = (model!=null?model.AccountServerUrl:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("socialLoginsInput").Checked = (model!=null?model.SocialLogins:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("disableRegistration").Checked = (model!=null?model.DisableRegistration:(bool?)null) ?? false;

                bool optAdmin = (model!=null?model.FilterLicense:(bool?)null) == true || (model!=null?model.DisallowSearchEngines:(bool?)null) == true || (model!=null?model.HideLoginUrlNutzungsbedingungenDatenschutz:(bool?)null) == true || (model!=null?model.InvitationUrl:(string)null) != null || (model!=null?model.Agb:(string)null) != null || (model!=null?model.Datenschutz:(string)null) != null || (model!=null?model.Impressum:(string)null) != null || (model!=null?model.Schlichtungsstellen:(string)null) != null || (model!=null?model.Beschwerdebearbeitung:(string)null) != null || (model!=null?model.Cookie:(string)null) != null;
                Document.GetElementById<HTMLInputElement>("optAdmin").Checked = optAdmin;
                Document.GetElementById<HTMLInputElement>("licenseFilter").Checked = (model!=null?model.FilterLicense:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("disallowSearchEngines").Checked = (model!=null?model.DisallowSearchEngines:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("hideLoginUrlNutzungsbedingungenDatenschutz").Checked = (model!=null?model.HideLoginUrlNutzungsbedingungenDatenschutz:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("invitationPageInput").Value = (model!=null?model.InvitationUrl:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("footerTextInput").Value = (model!=null?model.FooterText:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("agbInput").Value = (model!=null?model.Agb:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("datenschutzInput").Value = (model!=null?model.Datenschutz:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("impressumInput").Value = (model!=null?model.Impressum:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("schlichtungsstellenInput").Value = (model!=null?model.Schlichtungsstellen:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("beschwerdebearbeitungInput").Value = (model!=null?model.Beschwerdebearbeitung:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("cookieInput").Value = (model!=null?model.Cookie:(string)null) ?? "";

                Document.GetElementById<HTMLInputElement>("titelAppInput").Value = (model!=null?model.TitelApp:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("appServerUrlInput").Value = (model!=null?model.AppServerUrl:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("cssInput").Checked = (model!=null?model.CssInput:(bool?)null) ?? false;

                Document.GetElementById<HTMLInputElement>("welcomeHeadlineInput").Value = (model!=null?model.WelcomeHeadline:(string)null) ?? "";

                jQuery.Select(".colorpicker").ColorPicker();
                jQuery.Select("#colorPrimary").Parent().ColorPicker("setValue", (model!=null?model.ColorPrimary:(string)null) ?? "#0277bd");
                jQuery.Select("#colorAccent").Parent().ColorPicker("setValue", (model!=null?model.ColorAccent:(string)null) ?? "#03a9f4");
                jQuery.Select("#colorWarn").Parent().ColorPicker("setValue", (model!=null?model.ColorWarn:(string)null) ?? "#ff7b00");

                bool optApp = (model!=null?model.UsePreviewApp:(bool?)null) == true || (model!=null?model.AppApiEndpoint:(string)null) != null || (model!=null?model.AppAuthEndpoint:(string)null) != null || (model!=null?model.AppDriveEndpoint:(string)null) != null;
                Document.GetElementById<HTMLInputElement>("optApp").Checked = optApp;
                Document.GetElementById<HTMLInputElement>("usePreviewApp").Checked = (model!=null?model.UsePreviewApp:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("constAPIEndpoint_Input").Value = (model!=null?model.AppApiEndpoint:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("constAuthEndpoint_Input").Value = (model!=null?model.AppAuthEndpoint:(string)null) ?? "";
                Document.GetElementById<HTMLInputElement>("constDriveEndpoint_Input").Value = (model!=null?model.AppDriveEndpoint:(string)null) ?? "";

                Document.GetElementById<HTMLInputElement>("accountUseLetsEncryptInput").Checked = (model!=null?model.UseAccountLetsEncrypt:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("accountCertificateThumbprint").Value = (model!=null?model.AccountCertificateThumbprint:(string)null) ?? "";
DateTime accountExpiration;
                Document.GetElementById<HTMLInputElement>("accountCertificateExpiration").Value = DateTime.TryParse(model!=null?model.AccountCertificateExpiration:(string)null, out accountExpiration) && accountExpiration > DateTime.MinValue ? accountExpiration.ToString("dd.MM.yyyy HH:mm:ss") : "";

                Document.GetElementById<HTMLInputElement>("appUseLetsEncryptInput").Checked = (model!=null?model.UseAppLetsEncrypt:(bool?)null) ?? false;
                Document.GetElementById<HTMLInputElement>("appCertificateThumbprint").Value = (model!=null?model.AppCertificateThumbprint:(string)null) ?? "";
DateTime appExpiration;
                Document.GetElementById<HTMLInputElement>("appCertificateExpiration").Value = DateTime.TryParse(model!=null?model.AppCertificateExpiration:(string)null, out appExpiration) && appExpiration > DateTime.MinValue ? appExpiration.ToString("dd.MM.yyyy HH:mm:ss") : "";

                if (!String.IsNullOrEmpty(model!=null?model.LogoFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("logoPreview").Href = model!=null?model.LogoFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("logoPreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.CustomCssFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("stylePreview").Href = model!=null?model.CustomCssFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("stylePreview").Hidden = false;
                    Document.GetElementById<HTMLAnchorElement>("styleRemove").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.FaviconFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("faviconPreview").Href = model!=null?model.FaviconFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("faviconPreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.AgbFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("agbPreview").Href = model!=null?model.AgbFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("agbPreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.LogoAppFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("appLogoPreview").Href = model!=null?model.LogoAppFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("appLogoPreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.CustomCssAppFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("appCSSPreview").Href = model!=null?model.CustomCssAppFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("appCSSPreview").Hidden = false;
                    Document.GetElementById<HTMLAnchorElement>("appCSSRemove").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.FaviconAppFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("appFaviconPreview").Href = model!=null?model.FaviconAppFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("appFaviconPreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.MobileIconFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("mobileIconPreview").Href = model!=null?model.MobileIconFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("mobileIconPreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.WelcomeFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("welcomeImagePreview").Href = model!=null?model.WelcomeFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("welcomeImagePreview").Hidden = false;
                }
                if (!String.IsNullOrEmpty(model!=null?model.AgbFile:(string)null))
                {
                    Document.GetElementById<HTMLAnchorElement>("agbHtmlPreview").Href = model!=null?model.AgbFile:(string)null;
                    Document.GetElementById<HTMLAnchorElement>("agbHtmlPreview").Hidden = false;
                }

                SwitchCustomCss(Document.GetElementById<HTMLInputElement>("cssInput"));

                if (optAdmin)
                {
                    jQuery.Select("#erweiterteEinstellungenContent").RemoveClass("hide");
                }
                else
                {
                    jQuery.Select("#erweiterteEinstellungenContent").AddClass("hide");
                }

                if (optApp)
                {
                    jQuery.Select("#erweiterteEinstellungenConstContent").RemoveClass("hide");
                }
                else
                {
                    jQuery.Select("#erweiterteEinstellungenConstContent").AddClass("hide");
                }
            }
            Document.GetElementById<HTMLInputElement>("loadingBanner").Style.Display = "none";
        }

        public static async Task SetCustomizations(HTMLButtonElement sender)
        {
            using (new DisabledButton(sender, "Wird gespeichert..."))
            {
                HTMLInputElement accountServerUrlInput = Document.GetElementById<HTMLInputElement>("accountServerUrlInput");
                HTMLInputElement appServerUrlInput = Document.GetElementById<HTMLInputElement>("appServerUrlInput");

                var logo = Document.GetElementById<HTMLInputElement>("logoInput");
                var logoApp = Document.GetElementById<HTMLInputElement>("logoAppInput");
                var style = Document.GetElementById<HTMLInputElement>("styleInput");
                var styleapp = Document.GetElementById<HTMLInputElement>("styleAppInput");
                var favicon = Document.GetElementById<HTMLInputElement>("faviconInput");
                var faviconApp = Document.GetElementById<HTMLInputElement>("faviconAppInput");
                var mobileIcon = Document.GetElementById<HTMLInputElement>("mobileIconInput");
                var agbHtml = Document.GetElementById<HTMLInputElement>("agbHtmlInput");

                var welcomeImage = Document.GetElementById<HTMLInputElement>("welcomeImageInput");

                bool optApp = Document.GetElementById<HTMLInputElement>("optApp").Checked;
                bool optAdmin = Document.GetElementById<HTMLInputElement>("optAdmin").Checked;

                var accountCertificate = Document.GetElementById<HTMLInputElement>("accountCertificateInput");
                var accountCertificatePassword = Document.GetElementById<HTMLInputElement>("accountCertificatePasswordInput");
                var appCertificate = Document.GetElementById<HTMLInputElement>("appCertificateInput");
                var appCertificatePassword = Document.GetElementById<HTMLInputElement>("appCertificatePasswordInput");

                var customizations = new CustomizationConfiguration
                {
                    Titel = Document.GetElementById<HTMLInputElement>("titelInput").Value,
                    AccountServerUrl = accountServerUrlInput.Value,
                    SocialLogins = Document.GetElementById<HTMLInputElement>("socialLoginsInput").Checked,
                    DisableRegistration = Document.GetElementById<HTMLInputElement>("disableRegistration").Checked,
                    CssInput = Document.GetElementById<HTMLInputElement>("cssInput").Checked,
                    TitelApp = Document.GetElementById<HTMLInputElement>("titelAppInput").Value,
                    AppServerUrl = appServerUrlInput.Value,
                    AccountCertificatePassword = accountCertificatePassword.Value,
                    AppCertificatePassword = appCertificatePassword.Value,
                    WelcomeHeadline = Document.GetElementById<HTMLInputElement>("welcomeHeadlineInput").Value,
                    UseAccountLetsEncrypt = Document.GetElementById<HTMLInputElement>("accountUseLetsEncryptInput").Checked,
                    UseAppLetsEncrypt = Document.GetElementById<HTMLInputElement>("appUseLetsEncryptInput").Checked
                };

                if (!customizations.CssInput)
                {
                    customizations.ColorPrimary = Document.GetElementById<HTMLInputElement>("colorPrimary").Value;
                    customizations.ColorAccent = Document.GetElementById<HTMLInputElement>("colorAccent").Value;
                    customizations.ColorWarn = Document.GetElementById<HTMLInputElement>("colorWarn").Value;
                }

                if (optApp)
                {
                    customizations.UsePreviewApp = Document.GetElementById<HTMLInputElement>("usePreviewApp").Checked;
                    customizations.AppApiEndpoint = Document.GetElementById<HTMLInputElement>("constAPIEndpoint_Input").Value;
                    customizations.AppAuthEndpoint = Document.GetElementById<HTMLInputElement>("constAuthEndpoint_Input").Value;
                    customizations.AppDriveEndpoint = Document.GetElementById<HTMLInputElement>("constDriveEndpoint_Input").Value;
                }

                if (optAdmin)
                {
                    customizations.FilterLicense = Document.GetElementById<HTMLInputElement>("licenseFilter").Checked;
                    customizations.DisallowSearchEngines = Document.GetElementById<HTMLInputElement>("disallowSearchEngines").Checked;
                    customizations.HideLoginUrlNutzungsbedingungenDatenschutz = Document.GetElementById<HTMLInputElement>("hideLoginUrlNutzungsbedingungenDatenschutz").Checked;
                    customizations.InvitationUrl = Document.GetElementById<HTMLInputElement>("invitationPageInput").Value;
                    customizations.FooterText = Document.GetElementById<HTMLInputElement>("footerTextInput").Value;
                    customizations.Agb = Document.GetElementById<HTMLInputElement>("agbInput").Value;
                    customizations.Datenschutz = Document.GetElementById<HTMLInputElement>("datenschutzInput").Value;
                    customizations.Impressum = Document.GetElementById<HTMLInputElement>("impressumInput").Value;
                    customizations.Schlichtungsstellen = Document.GetElementById<HTMLInputElement>("schlichtungsstellenInput").Value;
                    customizations.Beschwerdebearbeitung = Document.GetElementById<HTMLInputElement>("beschwerdebearbeitungInput").Value;
                    customizations.Cookie = Document.GetElementById<HTMLInputElement>("cookieInput").Value;
                }

                var logoFile = System.Linq.Enumerable.FirstOrDefault<File>(logo.Files);
                var logoAppFile = System.Linq.Enumerable.FirstOrDefault<File>(logoApp.Files);
                var styleFile = System.Linq.Enumerable.FirstOrDefault<File>(style.Files);
                var styleAppFile = System.Linq.Enumerable.FirstOrDefault<File>(styleapp.Files);
                var faviconFile = System.Linq.Enumerable.FirstOrDefault<File>(favicon.Files);
                var agbHtmlFile = agbHtml!=null&&agbHtml.Files!=null?agbHtml.Files.FirstOrDefault():(File)null;
                var faviconAppFile = System.Linq.Enumerable.FirstOrDefault<File>(faviconApp.Files);
                var mobileIconFile = System.Linq.Enumerable.FirstOrDefault<File>(mobileIcon.Files);
                var accountCert = System.Linq.Enumerable.FirstOrDefault<File>(accountCertificate.Files);
                var appCert = System.Linq.Enumerable.FirstOrDefault<File>(appCertificate.Files);
                var welcomeImageFile = System.Linq.Enumerable.FirstOrDefault<File>(welcomeImage.Files);

                if (accountCert != null && String.IsNullOrWhiteSpace(customizations.AccountCertificatePassword))
                {
                    jQuery.Select(accountCertificatePassword).Notify("Das Password für das Zertifikat muss angegeben werden", NotifyStyle.Error);
                    return;
                }

                if (appCert != null && String.IsNullOrWhiteSpace(customizations.AppCertificatePassword))
                {
                    jQuery.Select(appCertificatePassword).Notify("Das Password für das Zertifikat muss angegeben werden", NotifyStyle.Error);
                    return;
                }

                if (String.IsNullOrWhiteSpace(accountServerUrlInput.Value) && (logoFile != null || styleFile != null || !String.IsNullOrWhiteSpace(customizations.Titel) || !String.IsNullOrWhiteSpace(customizations.InvitationUrl) || !String.IsNullOrWhiteSpace(customizations.Agb) || !String.IsNullOrWhiteSpace(customizations.Datenschutz) || !String.IsNullOrWhiteSpace(customizations.Impressum) || !String.IsNullOrWhiteSpace(customizations.Schlichtungsstellen) || !String.IsNullOrWhiteSpace(customizations.Beschwerdebearbeitung) || !String.IsNullOrWhiteSpace(customizations.Cookie)))
                {
                    jQuery.Select(accountServerUrlInput).Notify("Benutzerdefiniertes Aussehen erfordert eine eigene Url", NotifyStyle.Error);
                    return;
                }

                if (String.IsNullOrWhiteSpace(appServerUrlInput.Value) && (logoAppFile != null || styleAppFile != null || faviconAppFile != null || !String.IsNullOrWhiteSpace(customizations.TitelApp) || !String.IsNullOrWhiteSpace(customizations.AppApiEndpoint) || !String.IsNullOrWhiteSpace(customizations.AppAuthEndpoint) || !String.IsNullOrWhiteSpace(customizations.AppDriveEndpoint)))
                {
                    jQuery.Select(appServerUrlInput).Notify("Benutzerdefiniertes Aussehen erfordert eine eigene Url", NotifyStyle.Error);
                    return;
                }

                var accountServerUrl = String.IsNullOrWhiteSpace(accountServerUrlInput.Value) ? null : new Url(accountServerUrlInput.Value);
                var appServerUrl = String.IsNullOrWhiteSpace(appServerUrlInput.Value) ? null : new Url(appServerUrlInput.Value);
                if (accountServerUrl != null && (accountServerUrl!=null?accountServerUrl.Host.ToLower():(string)null) == (appServerUrl!=null?appServerUrl.Host.ToLower():(string)null))
                {
                    jQuery.Select(appServerUrlInput).Notify(string.Format("Account Server und App Server können nicht auf den selben Host \"{0}\" liegen",appServerUrl.Host), NotifyStyle.Error);
                    return;
                }

                try
                {
                    await AccountApi.SetCustomization(logoFile, logoAppFile, styleFile, styleAppFile, faviconFile, faviconAppFile, mobileIconFile, accountCert, appCert, customizations, welcomeImageFile, agbHtmlFile);
                    jQuery.Select(sender).Notify("Einstellungen gespeichert", NotifyStyle.Success);
                }
                catch (Exception ex)
                {
WebException wex;                    if ((wex = ex as WebException) != null&& wex.StatusCode >= 400 && wex.StatusCode <= 500)
                    {
                        var response = JsonConvert.DeserializeObject<ErrorResponse2>(wex.ResponseText);
                        if ((global::Bridge.Script.ToTemp("key1",response.Error)!=null?global::Bridge.Script.FromTemp<Error2>("key1").Reason:(string)null) != null)
                        {
                            jQuery.Select(sender).Notify(response.Error.Reason, new NotifySettings { AutoHide = false, AutoHideDelay = 60000, Style = NotifyStyle.Error });
                            return;
                        }
                    }
                    jQuery.Select(sender).Notify("Fehler beim Speichern der Einstellungen", NotifyStyle.Error);
                    return;
                }
                await LoadCustomizations(sender);
            }
        }

        public static async Task ResetCustomizations(HTMLButtonElement sender)
        {
            await AccountApi.ResetCustomization();
            await LoadCustomizations(sender);
            jQuery.Select(sender).Notify("Einstellungen zurückgesetzt", NotifyStyle.Success);
        }


        public static async Task RemoveCSS(HTMLButtonElement notifyElement)
        {
            await AccountApi.RemoveCss();
            await LoadCustomizations(null);

            jQuery.Select("#stylePreview").AddClass("hide");
            jQuery.Select("#styleRemove").AddClass("hide");
            jQuery.Select("#styleInput").Notify("CSS entfernt.", NotifyStyle.Success);
        }

        public static async Task RemoveAppCSS(HTMLButtonElement notifyElement)
        {
            await AccountApi.RemoveAppCss();
            await LoadCustomizations(null);

            jQuery.Select("#appCSSRemove").AddClass("hide");
            jQuery.Select("#appCSSPreview").AddClass("hide");
            jQuery.Select("#styleAppInput").Notify("CSS entfernt.", NotifyStyle.Success);
        }

        public static void SwitchCustomCss(HTMLInputElement sender)
        {
            if (sender.Checked)
            {
                jQuery.Select("#farbgebungTabs").AddClass("hide");
                jQuery.Select("#eigenesCSS").RemoveClass("hide");
            }
            else
            {
                jQuery.Select("#farbgebungTabs").RemoveClass("hide");
                jQuery.Select("#eigenesCSS").AddClass("hide");
            }
        }

        public static void SetPreview(HTMLInputElement sender, string previewId, string inputField, string inputFieldValue)
        {
File file;HTMLAnchorElement preview;            if ((file = System.Linq.Enumerable.FirstOrDefault<File>(sender.Files) as File) != null&& (preview = Document.GetElementById<HTMLAnchorElement>(previewId) as HTMLAnchorElement) != null)
            {
                var URL = Window.ToDynamic().URL.createObjectURL(file);
                preview.Href = URL;
                preview.Hidden = false;
HTMLInputElement inputFieldObj;                if ((inputFieldObj = Document.GetElementById<HTMLInputElement>(inputField) as HTMLInputElement) != null)
                {
                    inputFieldObj.Value = inputFieldValue;
                }
            }
        }

        public static async void LoadUserProfile()
        {
            var profile = await AccountApi.GetUserProfile();

            Document.GetElementById<HTMLInputElement>("Vorname").Value = profile.FirstName;
            Document.GetElementById<HTMLInputElement>("Nachname").Value = profile.LastName;

            if (Document.GetElementById<HTMLInputElement>("Anrede") != null)
            {
                Document.GetElementById<HTMLInputElement>("Anrede").Value = profile.Title;
                Document.GetElementById<HTMLInputElement>("Strasse").Value = profile.Street;
                Document.GetElementById<HTMLInputElement>("Hausnummer").Value = profile.Housenumber;
                Document.GetElementById<HTMLInputElement>("Land").Value = profile.Country ?? "DE";
                Document.GetElementById<HTMLInputElement>("Plz").Value = profile.Plz;
                Document.GetElementById<HTMLInputElement>("Ort").Value = profile.City;
                Document.GetElementById<HTMLInputElement>("Phone").Value = profile.Phone;
            }
        }

        public static async void SaveUserProfile(HTMLButtonElement sender)
        {
            var profile = new UserProfileModel();

            profile.FirstName = Document.GetElementById<HTMLInputElement>("Vorname").Value;
            profile.LastName = Document.GetElementById<HTMLInputElement>("Nachname").Value;

            if (Document.GetElementById<HTMLInputElement>("Anrede") != null)
            {
                profile.Title = Document.GetElementById<HTMLInputElement>("Anrede").Value;
                profile.Street = Document.GetElementById<HTMLInputElement>("Strasse").Value;
                profile.Housenumber = Document.GetElementById<HTMLInputElement>("Hausnummer").Value;
                profile.Country = Document.GetElementById<HTMLInputElement>("Land").Value;
                profile.Plz = Document.GetElementById<HTMLInputElement>("Plz").Value;
                profile.City = Document.GetElementById<HTMLInputElement>("Ort").Value;
                profile.Phone = Document.GetElementById<HTMLInputElement>("Phone").Value;
            }
            if (!String.IsNullOrWhiteSpace(profile.LastName))
            {
                await AccountApi.SaveUserProfile(profile);

                jQuery.Select(sender).Notify("Gespeichert", NotifyStyle.Success);
            }
            else
            {
                jQuery.Select(sender).Notify("Die Angabe des Nachnamens ist verpflichtend", NotifyStyle.Error);
            }
        }
    }
}
