using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.Html5;
using Scripts;

namespace AccountScripts
{
    public static class LinkedAccounts
    {
        public static async Task SendTestEmail(HTMLButtonElement sender)
        {
            var output = Document.GetElementById<HTMLDivElement>("emailTestOutput");
            output.ClassName = "alert alert-info";
Scripts.Extensions.SetText<HTMLDivElement>(            output,"E-Mail Zugangsdaten werden getestet.");
            var emailAccount = new EmailAccountModel();

            emailAccount.SMTPHost = Document.GetElementById<HTMLInputElement>("SMTPHost").Value;
            emailAccount.SMTPPort = Convert.ToInt32(Document.GetElementById<HTMLInputElement>("SMTPPort").Value);
            emailAccount.IMAPHost = Document.GetElementById<HTMLInputElement>("IMAPHost").Value;
            emailAccount.IMAPPort = Convert.ToInt32(Document.GetElementById<HTMLInputElement>("IMAPPort").Value);
            emailAccount.Password = Document.GetElementById<HTMLInputElement>("Password").Value;
            emailAccount.SecureSocketOptions = Convert.ToInt32(Document.GetElementById<HTMLInputElement>("SecureSocketOptions").Value);
            emailAccount.From = Document.GetElementById<HTMLInputElement>("From").Value;
            emailAccount.Displayname = Document.GetElementById<HTMLInputElement>("DisplayName").Value;
            emailAccount.Username = Document.GetElementById<HTMLInputElement>("Username").Value;

            var receiver = Document.GetElementById<HTMLInputElement>("testEmailTarget").Value;
            var result = await Web.PostAsync<List<string>>(string.Format("{0}?receiver={1}",Endpoints.LinkedAccountsSendTestEmail,receiver), new RequestOptions { Body = emailAccount });

            foreach (string msg in result)
            {
                var element = new HTMLDivElement();
Scripts.Extensions.SetText<HTMLDivElement>(                element,msg);
                output.AppendChild(element);
            }
        }
    }

    public class EmailAccountModel
    {
        public virtual Guid Id { get; set; }
        public virtual string IMAPHost { get; set; } 
        public virtual int IMAPPort { get; set; }
        public virtual string SMTPHost { get; set; } 
        public virtual int SMTPPort { get; set; }
        public virtual string From { get; set; }
        public virtual string Displayname { get; set; }
        public virtual int SecureSocketOptions { get; set; }
        public virtual string Username { get; set; } 
        public virtual string Password { get; set; } 

        //public virtual string AccessToken { get; set; } = String.Empty;
    
private string __Property__Initializer__IMAPHost="";private string __Property__Initializer__SMTPHost="";private string __Property__Initializer__Username="";private string __Property__Initializer__Password="";}
}
