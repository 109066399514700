using System;
using System.Collections.Generic;

namespace Assfinet.Account.Common.Models
{
    public sealed class UserProfileModel
    {
        public object AdditionalData { get; set; }
        public string Email { get; set; }
        public string Title { get; set; }
        public string FirstName { get; set; }
        public string LastName { get; set; }
        public string Street { get; set; }
        public string Housenumber { get; set; }
        public string Country { get; set; }
        public string Plz { get; set; }
        public string City { get; set; }
        public string Phone { get; set; }
        public bool TwoFactorEnabled { get; set; }
        public bool UseAuthenticator { get; set; }   
        public List<string> AvailableLoginProviders { get; set; }
        public List<string> ConfiguredLoginProviders { get; set; }
public bool UserProfileComplete
{
    get
    {
        return !String.IsNullOrWhiteSpace(Email) && !String.IsNullOrWhiteSpace(Title) && !String.IsNullOrWhiteSpace(FirstName) && !String.IsNullOrWhiteSpace(LastName) && !String.IsNullOrWhiteSpace(Street) && !String.IsNullOrWhiteSpace(Housenumber) && !String.IsNullOrWhiteSpace(Country) && !String.IsNullOrWhiteSpace(Plz) && !String.IsNullOrWhiteSpace(City) && !String.IsNullOrWhiteSpace(Phone);
    }
}
    
private bool __Property__Initializer__TwoFactorEnabled=false;private bool __Property__Initializer__UseAuthenticator=false;}
}
