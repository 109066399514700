using System;
using Bridge.Html5;

namespace AccountScripts
{

    public sealed class Disabled : IDisposable
    {
        private readonly HTMLDivElement ui;

        public Disabled(HTMLDivElement obj)
        {
            ui = obj as HTMLDivElement;
            ui.ClassList.Add("disabled-content");
        }

        public void Dispose()
        {
            ui.ClassList.Remove("disabled-content");
        }
    }
}
