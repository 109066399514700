using System;
using Bridge.Html5;
using Scripts;

namespace AccountScripts
{
    public sealed class DisabledButton : IDisposable
    {
        private readonly HTMLButtonElement ui;
        private readonly string oldText;

        public DisabledButton(HTMLButtonElement obj, string newText)
        {
            ui = obj;
            ui.Disabled = true;
            oldText = ui.TextContent;
Scripts.Extensions.SetText<HTMLButtonElement>(            ui,newText);
        }

        public void Dispose()
        {
            ui.Disabled = false;
Scripts.Extensions.SetText<HTMLButtonElement>(            ui,oldText);
        }
    }
}
