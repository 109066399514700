using System;
using System.Threading.Tasks;
using Bridge;
using Bridge.Bootstrap3;
using Bridge.Html5;
using Bridge.jQuery2;
using Scripts;

namespace AccountScripts
{
    public static class EmailSettings
    {
        public static void UpdateDisableds()
        {
            var select = Document.GetElementById<HTMLSelectElement>("UseDefaultSettings");
            if (select != null)
            {
                var inputs = jQuery.Select("#emailSettingsGroup :input");
                if (select.Value == "true")
                {
                    inputs.Attr("disabled", "disabled");
                }
                else
                {
                    inputs.RemoveAttr("disabled");
                }
            }
        }

        public static async Task SendTestEmail(HTMLButtonElement sender)
        {
            var output = Document.GetElementById<HTMLDivElement>("emailTestOutput");
            output.ClassName = "alert alert-info";
Scripts.Extensions.SetText<HTMLDivElement>(            output,"E-Mail wird gesendet...");

            var emailTarget = Document.GetElementById<HTMLInputElement>("testEmailTarget");
            var result = await Web.PostAsync<TestEmailResult>(Endpoints.SettingsSendTestEmail, Script.ToPlainObject(new { receiver = emailTarget.Value }));

            if (!result.Result)
            {
                output.ClassName = "alert alert-danger";
                output.InnerHTML = "";
                foreach (string error in result.Errors)
                {
                    var element = new HTMLDivElement();
Scripts.Extensions.SetText<HTMLDivElement>(                    element,error);
                    output.AppendChild(element);
                }
            }
            else
            {
                output.ClassName = "alert alert-success";
Scripts.Extensions.SetText<HTMLDivElement>(                output,"E-Mail erfolgreich verschickt. Bitte prüfen Sie ihren Posteingang auf erhalt der E-Mail.");
            }

            if (!String.IsNullOrEmpty(result.ProtocolDump))
            {
                var showProtocolButton = Scripts.Extensions.SetText<HTMLButtonElement>(new HTMLButtonElement
                {
                    Type = ButtonType.Button,
                    ClassName = "btn btn-default"
                },"Protokoll anzeigen");
                showProtocolButton.OnClick = x =>
                {
                    output.AppendChild(new HTMLPreElement
                    {
                        TextContent = result.ProtocolDump
                    });
                    output.RemoveChild(showProtocolButton);
                };
                output.AppendChild(showProtocolButton);
            }
        }

        public static void PreviewEmailTemplate()
        {
            var body = new HTMLDivElement { InnerHTML = jQuery.Select("#Body").Val() };
            var email = new HTMLDivElement();
            email.AppendChild(body);
            var previewBody = Document.GetElementById<HTMLDivElement>("previewBody");
            previewBody.InnerHTML = "";
            previewBody.AppendChild(email);
            jQuery.Select("#emailPreviewModal").ModalShow();
        }

        public static async Task LoadSelectedEmailTemplate(HTMLSelectElement sender)
        {
            var template = await Web.PostAsync<EmailTemplateModel>(Endpoints.SettingsGetEmailTemplate, Script.ToPlainObject(new { type = sender.Value }));
            jQuery.Select("#SendMail").Val(template.SendMail ? "true" : "false");
            jQuery.Select("#UseDefaultTemplates").Val(template.UseDefaultTemplates ? "true" : "false");

            SetEmailTemplateControls();
        }

        public static void SetEmailTemplateControls()
        {
            var select = Document.GetElementById<HTMLSelectElement>("UseDefaultTemplates");

            var inputs = jQuery.Select("#defaultTemplateGroup :input");
            if (select.Value == "true")
            {
                inputs.Attr("disabled", "disabled");
            }
            else
            {
                inputs.RemoveAttr("disabled");
            }

            LoadEmailTemplate();
        }

        private static async void LoadEmailTemplate()
        {
            var emailTemplateEreignis = jQuery.Select("#ereignis");

            if (emailTemplateEreignis.Val() == "0")
            {
                jQuery.Select("#Subject").Val("");
                jQuery.Select("#Body").Val("");
                return;
            }

            bool isStandard = jQuery.Select("#UseDefaultTemplates").Val() == "true";
            var endpoint = isStandard ? Endpoints.SettingsGetDefaultEmailTemplate : Endpoints.SettingsGetEmailTemplate;
            var template = await Web.PostAsync<EmailTemplateModel>(endpoint, Script.ToPlainObject(new { type = emailTemplateEreignis.Val() }));

            jQuery.Select("#Subject").Val(template.Subject);
            jQuery.Select("#Body").Val(template.Body);
        }
    }

    [ObjectLiteral]
    public sealed class EmailTemplateModel
    {
        public bool SendMail;
        public bool UseDefaultTemplates;
        public string Subject;
        public string Body;
    }

    [ObjectLiteral]
    public sealed class TestEmailResult
    {
        public bool Result;
        public string[] Errors;
        public string ProtocolDump;
    }
}
