using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using Bridge.Html5;

namespace DriveScripts
{
    public sealed class Url
    {
        private readonly List<KeyValuePair<string, string>> _params = new List<KeyValuePair<string, string>>();
        public string Protokoll { get; set; }
        public string Host { get; set; }
        private string path;
        public string Path
        {
            get { return path; }
            set { path = value.StartsWith("/") ? value : "/" + value; }
        }

        public static string ToAbsolute(string baseUrl, string path)
        {
            var url = new Url(baseUrl);
            url.Path = path;
            return url.ToString();
        }

        public string Anchor { get; set; }

        public Url()
        {
            Protokoll = String.Empty;
            Host = String.Empty;
            Path = String.Empty;
            Anchor = String.Empty;
        }

        public Url(string url)
            : this()
        {
            if (String.IsNullOrEmpty(url))
            {
                throw new ArgumentException("Url ist erforderlich", "url");
            }

            int protokollDelimiterIndex = url.IndexOf("://");
            if (protokollDelimiterIndex != -1)
            {
                Protokoll = url.Substring(0, protokollDelimiterIndex);
                url = url.Remove(0, Protokoll.Length + 3);
            }

            int slashIndex = url.IndexOf('/');
            if (slashIndex != -1)
            {
                if (slashIndex == 0)
                {
                    Host = "";
                }
                else
                {
                    Host = url.Substring(0, slashIndex);
                    url = url.Remove(0, slashIndex);
                }

                int anchorIndex = url.IndexOf('#');
                if (anchorIndex != -1)
                {
                    Anchor = url.Substring(anchorIndex + 1, url.Length - anchorIndex - 1);
                    url = url.Remove(anchorIndex, url.Length - anchorIndex);
                }

                int questionMarkIndex = url.IndexOf('?');
                if (questionMarkIndex != -1)
                {
                    Path = url.Substring(0, questionMarkIndex);
                    url = url.Remove(0, questionMarkIndex + 1);

                    string[] undsplit = url.Split(new[] { "&" }, StringSplitOptions.RemoveEmptyEntries);

                    foreach (string split in undsplit)
                    {
                        string[] gleichsplit = split.Split(new[] { "=" }, 2, StringSplitOptions.RemoveEmptyEntries);

                        if (gleichsplit.Length == 0)
                        {
                            continue;
                        }

                        string key = Window.DecodeURIComponent(gleichsplit[0]);
                        string value = String.Empty;
                        if (gleichsplit.Length > 1)
                        {
                            value = Window.DecodeURIComponent(gleichsplit[1]);
                        }
                        _params.Add(new KeyValuePair<string, string>(key, value));
                    }
                }
                else
                {
                    Path = url;
                }
            }
            else
            {
                Host = url;
            }
        }

        public bool TryGetParameter(string key, out string value)
        {
            var result = System.Linq.Enumerable.SingleOrDefault<KeyValuePair<string,string>>(_params,(Func<KeyValuePair<string,string>,bool>)(x => x.Key == key));
            if (!result.Equals(default(KeyValuePair<string, string>)))
            {
                value = result.Value;
                return true;
            }
            value = null;
            return false;
        }

        public string GetParameter(string key)
        {
            return System.Linq.Enumerable.Single<KeyValuePair<string,string>>(_params,(Func<KeyValuePair<string,string>,bool>)(x => x.Key == key)).Value;
        }

        public void SetParameter(string key, string value)
        {
            RemoveParameter(key);
            AppendParameter(key, value);
        }

        public void AppendParameter(string key, string value)
        {
            _params.Add(new KeyValuePair<string, string>(key, value));
        }

        public void RemoveParameter(string key)
        {
            for (int i = 0; i < _params.Count; i++)
            {
                if (_params[i].Key == key)
                {
                    _params.RemoveAt(i);
                    i--;
                }
            }
        }

        public void RemoveParameter(string key, string value)
        {
            for (int i = 0; i < _params.Count; i++)
            {
                if (_params[i].Key == key && _params[i].Value == value)
                {
                    _params.RemoveAt(i);
                    i--;
                }
            }
        }

        public override string ToString()
        {
            var sb = new StringBuilder();
            if (!String.IsNullOrEmpty(Protokoll))
            {
                sb.Append(Protokoll + "://");
            }
            sb.Append(Host);
            sb.Append(Path);
            if (_params.Count > 0)
            {
                sb.Append("?");
                sb.Append(System.Linq.Enumerable.Select<KeyValuePair<string,string>,string>(_params,(Func<KeyValuePair<string,string>,string>)(x => String.Format("{0}={1}", Window.EncodeURIComponent(x.Key), Window.EncodeURIComponent(x.Value)))).ToArray().Join("&"));
            }
            if (!String.IsNullOrEmpty(Anchor))
            {
                sb.Append("#");
                sb.Append(Anchor);
            }
            return sb.ToString();
        }
    }
}
